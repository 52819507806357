.svg-common() {
	background: url("../svg/dist/css/css-sprite.svg") no-repeat;
}

.svg-account-icon() {
.svg-common();
background-position: 0 0;
}

.svg-account-icon-dims() {
	width: 26px;
height: 26px;
}

.svg-arrow-blue-icon() {
.svg-common();
background-position: 0 2.576808721506442%;
}

.svg-arrow-blue-icon-dims() {
	width: 26px;
height: 26px;
}

.svg-arrow-down-icon() {
.svg-common();
background-position: 0 5.138339920948616%;
}

.svg-arrow-down-icon-dims() {
	width: 20px;
height: 23px;
}

.svg-arrow-down-icon-blue() {
.svg-common();
background-position: 0 7.41106719367589%;
}

.svg-arrow-down-icon-blue-dims() {
	width: 20px;
height: 23px;
}

.svg-arrow-icon() {
.svg-common();
background-position: 0 9.551656920077972%;
}

.svg-arrow-icon-dims() {
	width: 12px;
height: 9px;
}

.svg-arrow-next-icon() {
.svg-common();
background-position: 0 10.479921645445641%;
}

.svg-arrow-next-icon-dims() {
	width: 16px;
height: 14px;
}

.svg-arrow-next-icon-blue() {
.svg-common();
background-position: 0 11.851126346718903%;
}

.svg-arrow-next-icon-blue-dims() {
	width: 16px;
height: 14px;
}

.svg-arrow-right-icon-blue() {
.svg-common();
background-position: 0 13.170731707317072%;
}

.svg-arrow-right-icon-blue-dims() {
	width: 8px;
height: 10px;
}

.svg-arrow-white-icon() {
.svg-common();
background-position: 0 14.370664023785926%;
}

.svg-arrow-white-icon-dims() {
	width: 26px;
height: 26px;
}

.svg-cart-add-icon-blue() {
.svg-common();
background-position: 0 16.94747274529237%;
}

.svg-cart-add-icon-blue-dims() {
	width: 26px;
height: 26px;
}

.svg-cart-add-icon-white() {
.svg-common();
background-position: 0 19.52428146679881%;
}

.svg-cart-add-icon-white-dims() {
	width: 26px;
height: 26px;
}

.svg-cart-icon() {
.svg-common();
background-position: 0 22.101090188305253%;
}

.svg-cart-icon-dims() {
	width: 26px;
height: 26px;
}

.svg-cart-icon-white() {
.svg-common();
background-position: 0 24.62908011869436%;
}

.svg-cart-icon-white-dims() {
	width: 36px;
height: 24px;
}

.svg-check-icon-blue() {
.svg-common();
background-position: 0 26.686217008797655%;
}

.svg-check-icon-blue-dims() {
	width: 15px;
height: 12px;
}

.svg-check-icon-green() {
.svg-common();
background-position: 0 27.85923753665689%;
}

.svg-check-icon-green-dims() {
	width: 15px;
height: 12px;
}

.svg-check-icon-white() {
.svg-common();
background-position: 0 29.08912830558276%;
}

.svg-check-icon-white-dims() {
	width: 14px;
height: 14px;
}

.svg-chevron-left() {
.svg-common();
background-position: 0 30.945273631840795%;
}

.svg-chevron-left-dims() {
	width: 19px;
height: 30px;
}

.svg-chevron-left-blue() {
.svg-common();
background-position: 0 33.930348258706466%;
}

.svg-chevron-left-blue-dims() {
	width: 19px;
height: 30px;
}

.svg-chevron-right() {
.svg-common();
background-position: 0 36.91542288557214%;
}

.svg-chevron-right-dims() {
	width: 19px;
height: 30px;
}

.svg-chevron-right-blue() {
.svg-common();
background-position: 0 39.90049751243781%;
}

.svg-chevron-right-blue-dims() {
	width: 19px;
height: 30px;
}

.svg-compare-icon() {
.svg-common();
background-position: 0 42.379547689282205%;
}

.svg-compare-icon-dims() {
	width: 23px;
height: 18px;
}

.svg-compare-icon-lightblue() {
.svg-common();
background-position: 0 44.14945919370698%;
}

.svg-compare-icon-lightblue-dims() {
	width: 23px;
height: 18px;
}

.svg-cross-icon() {
.svg-common();
background-position: 0 46.28344895936571%;
}

.svg-cross-icon-dims() {
	width: 26px;
height: 26px;
}

.svg-delete-icon() {
.svg-common();
background-position: 0 48.667324777887465%;
}

.svg-delete-icon-dims() {
	width: 22px;
height: 22px;
}

.svg-delete-icon-blue() {
.svg-common();
background-position: 0 50.8390918065153%;
}

.svg-delete-icon-blue-dims() {
	width: 22px;
height: 22px;
}

.svg-delete-icon-red() {
.svg-common();
background-position: 0 52.49266862170088%;
}

.svg-delete-icon-red-dims() {
	width: 15px;
height: 12px;
}

.svg-filter() {
.svg-common();
background-position: 0 54.41030723488603%;
}

.svg-filter-dims() {
	width: 26px;
height: 26px;
}

.svg-icon-mail() {
.svg-common();
background-position: 0 57.345168046275056%;
}

.svg-icon-mail-dims() {
	width: 34.8px;
height: 32.3px;
}

.svg-icon-phone() {
.svg-common();
background-position: 0 60.8%;
}

.svg-icon-phone-dims() {
	width: 36px;
height: 35px;
}

.svg-list-icon() {
.svg-common();
background-position: 0 63.916500994035786%;
}

.svg-list-icon-dims() {
	width: 21px;
height: 29px;
}

.svg-long-arrow-right-icon() {
.svg-common();
background-position: 0 65.6891495601173%;
}

.svg-long-arrow-right-icon-dims() {
	width: 14px;
height: 12px;
}

.svg-long-arrow-right-icon-lightblue() {
.svg-common();
background-position: 0 66.86217008797654%;
}

.svg-long-arrow-right-icon-lightblue-dims() {
	width: 14px;
height: 12px;
}

.svg-mail-icon() {
.svg-common();
background-position: 0 70.09063444108762%;
}

.svg-mail-icon-dims() {
	width: 42px;
height: 42px;
}

.svg-menu-icon-blue() {
.svg-common();
background-position: 0 72.85291214215202%;
}

.svg-menu-icon-blue-dims() {
	width: 30px;
height: 22px;
}

.svg-menu-icon-darkblue() {
.svg-common();
background-position: 0 75.02467917077986%;
}

.svg-menu-icon-darkblue-dims() {
	width: 30px;
height: 22px;
}

.svg-menu-icon-white() {
.svg-common();
background-position: 0 77.50247770069376%;
}

.svg-menu-icon-white-dims() {
	width: 26px;
height: 26px;
}

.svg-minus-icon() {
.svg-common();
background-position: 0 79.44936086529007%;
}

.svg-minus-icon-dims() {
	width: 18px;
height: 18px;
}

.svg-phone-icon() {
.svg-common();
background-position: 0 82.68268268268268%;
}

.svg-phone-icon-dims() {
	width: 36px;
height: 36px;
}

.svg-pin-icon() {
.svg-common();
background-position: 0 86.80765357502517%;
}

.svg-pin-icon-dims() {
	width: 42px;
height: 42px;
}

.svg-plus-icon() {
.svg-common();
background-position: 0 88.88888888888889%;
}

.svg-plus-icon-dims() {
	width: 18px;
height: 18px;
}

.svg-remove-icon() {
.svg-common();
background-position: 0 90.30362389813908%;
}

.svg-remove-icon-dims() {
	width: 14px;
height: 14px;
}

.svg-search-icon() {
.svg-common();
background-position: 0 93.04174950298211%;
}

.svg-search-icon-dims() {
	width: 28px;
height: 29px;
}

.svg-search-icon-white() {
.svg-common();
background-position: 0 95.45004945598417%;
}

.svg-search-icon-white-dims() {
	width: 24px;
height: 24px;
}

.svg-trash-icon() {
.svg-common();
background-position: 0 97.72727272727273%;
}

.svg-trash-icon-dims() {
	width: 20px;
height: 23px;
}

.svg-trash-icon-darkblue() {
.svg-common();
background-position: 0 100%;
}

.svg-trash-icon-darkblue-dims() {
	width: 20px;
height: 23px;
}

